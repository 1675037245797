import axios from "axios";
import { mutationsType } from "@/store/mutations-type.js";
import store from "@/store";
import { Message } from "element-ui";
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
import router from "../router";
import { ROUTER_PATH } from "@/constants/common.js";
const axiosRequest = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
});
let needLoadingCount = 0;
let needReject = true;
const showFullScreenLoading = () => {
  if (needLoadingCount === 0) {
    store.commit(mutationsType.SHOW_LOADING);
  }
  needLoadingCount++;
};
const hideFullScreenLoading = () => {
  if (needLoadingCount <= 0) {
    return;
  }
  needLoadingCount--;
  if (needLoadingCount === 0) {
    store.commit(mutationsType.HIDE_LOADING);
  }
};

// const getResponseErrorMsg = errorCode => {
//   // TODO
//   console.log(errorCode);

//   return "code is error";
// };

axiosRequest.interceptors.request.use(
  config => {
    showFullScreenLoading();
    const token = localStorage.getItem(LOCAL_STORAGE_KEY.RPA_TOKEN);
    const wkVersion = process.env.VUE_APP_VERSION;
    // config.headers["Access-Control-Allow-Origin"]= "*";
    //const expire = localStorage.getItem(LOCAL_STORAGE_KEY.RPA_TOKEN_EXPIRE);
    if (token) {
      //if (Date.now() < expire) {
        config.headers.Authorization = `Bearer ${token}`;
      //}
    }
    if (wkVersion) {
        config.headers.WorkstationVersion = `${wkVersion}`;
    }
    needReject = true;
    if (config.data.needReject == false) {
        needReject = false;
    }
    return config;
  },
  error => {
    // do something with request error
    hideFullScreenLoading();
    Message({
      showClose: true,
      message: error || "Request Error",
      type: "error"
    });

    return Promise.reject(error);
  }
);
// response interceptor
axiosRequest.interceptors.response.use(
  response => {
    hideFullScreenLoading();
    const res = response.data;
    if (res.code !== "000") {
      // const errorMessage = getResponseErrorMsg(res.code);

      Message({
        showClose: true,
        message: res.message,
        type: "error"
      });
      // TODO:ERROR处理

      if (res.code === "666") {
        router.push(ROUTER_PATH.HOME);
      }
      if (needReject) {
          return Promise.reject(new Error(res.message || "Data Error"));
      } else {
          return res;
      }
    } else {
      return res;
    }
  },
  error => {
    hideFullScreenLoading();
    Message({
      showClose: true,
      message: error.message,
      type: "error"
    });
    return Promise.reject(error);
  }
);

export default axiosRequest;
